// https://i18n.nuxtjs.org/guide/lang-switcher#dynamic-route-parameters
import type { StringNonNullMultiLocaleField } from "~~/server/graphql/datocms/generated";

export function useDatoSlugsToI18n(allSlugLocales: StringNonNullMultiLocaleField[] | undefined) {
  if (!allSlugLocales) {
    return;
  }

  const i18nparams = allSlugLocales.reduce((acc: Record<string, unknown>, item: StringNonNullMultiLocaleField) => {
    const cult = item.locale!.replace("_", "-").toLowerCase();
    acc[cult] = { slug: item.value === "home" ? "" : item.value };
    return acc;
  }, {});

  const setI18nParams = useSetI18nParams();
  setI18nParams(i18nparams);
};
